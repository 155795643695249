import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { getImageUrl } from '@takeshape/routing';
import {
  InstantSearch,
  connectInfiniteHits,
  connectStats,
  Configure,
  connectSortBy,
} from 'react-instantsearch-dom';

import Layout from '../components/Layout';
import SearchBox from '../components/search/SearchBox';
import StoryCard from '../components/search/StoryCard';
import RefinementList from '../components/search/RefinementList';

import algoliaSearchClient from '../services/algolia';

import imageCtaBannerBG from '../assets/images/bg/story/cta-banner-bg.png';

const StoryPage = ({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    takeshape: {
      getLandingPage: { seo },
      getHomePage: { shortStories },
    },
  },
}) => {
  return (
    <Layout
      title={seo.title}
      description={seo.description}
      image={getImageUrl(seo.image.path)}
      url={siteUrl}
    >
      <InstantSearch indexName="Stories" searchClient={algoliaSearchClient}>
        <Configure hitsPerPage={21} />

        <div className="mt-12 md:mt-16 flex gap-4 flex-wrap items-center px-4 lg:px-7">
          <HeadlineResults />

          <div className="grow shrink flex gap-4 justify-between flex-wrap sm:flex-nowrap">
            <SortBy
              defaultRefinement="Stories_createdAt_desc"
              items={[
                { value: 'Stories_createdAt_asc', label: 'Oldest' },
                { value: 'Stories_createdAt_desc', label: 'Newest' },
                { value: 'Stories_total_viewCount_desc', label: 'Most Viewed' },
              ]}
            />
            <SearchBox />
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-5 lg:min-h-screen pb-4 px-4 lg:px-7">
          <section className="col-span-12 lg:col-span-3">
            <div className="mt-4 lg:mt-7 border border-white shadow-md p-6 rounded-xl flex flex-col justify-between md:sticky md:top-8 gap-y-4 lg:gap-y-7">
              <div className="max-h-[600px] overflow-y-auto">
                <RefinementList
                  attribute="tags.tagTitle"
                  field="Tags"
                  title="Tags"
                  limit={10}
                  showMore
                  showMoreLimit={1000}
                  operator="and"
                />
              </div>
              <div className="max-h-[600px] overflow-y-auto">
                <RefinementList
                  attribute="sponsors.sponsorName"
                  field="Sponsors"
                  title="Sponsors"
                  limit={10}
                  showMore
                  showMoreLimit={1000}
                  operator="and"
                />
              </div>
            </div>
          </section>
          <section className="col-span-12 lg:col-span-9">
            <div className="mt-4 lg:mt-7">
              <CtaBanner shortStories={shortStories} />
              <StoriesInfiniteHits />
            </div>
          </section>
        </div>
      </InstantSearch>
    </Layout>
  );
};

const SortBy = connectSortBy(({ items, refine }) => (
  <select
    className="w-full rounded-3xl relative shadow-md border-black-light p-md px-6 h-[50px] max-w-[150px] appearance-none bg-no-repeat"
    onChange={(event) => {
      event.preventDefault();
      refine(event.target.value);
    }}
    style={{
      backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
      backgroundPositionX: '98%',
      backgroundPositionY: '50%',
    }}
  >
    {items.map((item) => (
      <option key={item.value} value={item.value} selected={item.isRefined}>
        {item.label}
      </option>
    ))}
  </select>
));

const HeadlineResults = connectStats(({ nbHits }) => {
  const location = useLocation();
  const queryStringParsed = queryString.parse(location.search);
  const isActiveFilter = Object.keys(queryStringParsed).length > 0;
  const queriesValuesString = Object.values(queryStringParsed).join(', ');

  const headlineText = isActiveFilter
    ? `${nbHits} Stories that match '${queriesValuesString}'`
    : 'Stories';
  return <h1 className="h2 font-knockout uppercase grow shrink">{headlineText}</h1>;
});

const CtaBanner = ({ shortStories }) => (
  <div
    className="mb-6"
    style={{ background: 'linear-gradient(270deg, #6A72B1 -0.76%, #0B0D13 99.58%)' }}
  >
    <div
      className="h-full w-full md:bg-right bg-no-repeat bg-[length:auto_100%]"
      style={{
        backgroundImage: `url(${imageCtaBannerBG})`,
      }}
    >
      <div className="flex flex-wrap items-center justify-between px-7 py-4 h-full w-full min-h-[110px] gap-4">
        <h3 className="font-knockout uppercase inline-flex flex-wrap items-center gap-2">
          <span className="h4 text-red">What’s</span>
          <span className="h3" style={{ WebkitTextStroke: '1px white' }}>
            Your
          </span>
          <span className="h3 text-white">Mat Made</span>
          <span className="h4" style={{ WebkitTextStroke: '1px white' }}>
            story?
          </span>
        </h3>

        <a
          href={shortStories.buttonUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="rounded font-bold bg-red py-3 px-5 text-white uppercase"
        >
          {shortStories.buttonText}
        </a>
      </div>
    </div>
  </div>
);

const StoriesInfiniteHits = connectInfiniteHits(({ hits, hasMore, refineNext }) => {
  return (
    <>
      <div className="grid grid-cols-12 gap-y-4 md:gap-8">
        {hits.map((hit) => (
          <div key={hit.objectID} className="col-span-12 md:col-span-4 lg:col-span-6 xl:col-span-4">
            <StoryCard data={hit} />
          </div>
        ))}
      </div>
      {hasMore && (
        <div className="text-center">
          <button
            className="rounded font-bold bg-red py-3 px-5 text-white uppercase mt-6"
            onClick={refineNext}
            type="button"
          >
            Show more
          </button>
        </div>
      )}
    </>
  );
});

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    takeshape {
      getLandingPage {
        seo {
          description
          title
          image {
            path
          }
        }
      }
      getHomePage {
        shortStories {
          buttonUrl
          buttonText
        }
      }
    }
  }
`;

export default StoryPage;
