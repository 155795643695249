import React, { useState, useEffect, useMemo } from 'react';
import { getImageUrl } from '@takeshape/routing';
import ReactPlayer from 'react-player/youtube';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

// import { SOCIAL_PROVIDER } from '../../constants/common';
import { getSocialProvider, abbreviateNumber, setRouterSearchQuery } from '../../utils/common';
import { getTikTokThumbnail } from '../../utils/api';

import iconIG from '../../assets/images/icons/social/instagram.svg';
import iconYT from '../../assets/images/icons/social/youtube.svg';
import iconTT from '../../assets/images/icons/social/tiktok.svg';
import iconPlay from '../../assets/images/icons/play-btn.svg';

const StoryCard = ({ data }) => {
  const location = useLocation();
  const queryStringParsed = queryString.parse(location.search);

  const socialProvider = data?.primaryVideoLink && getSocialProvider(data.primaryVideoLink);

  const filterClicked = (tagTitle) => {
    setRouterSearchQuery({ location, queryStringParsed }, tagTitle, 'Tags');
  };

  return (
    <div className="block h-full bg-white rounded-b-xl shadow-md overflow-hidden">
      <div className="flex flex-wrap">
        <div className="w-full relative h-[490px] lg:h-[600px] overflow-hidden">
          <VideoThumbnail
            data={data}
            socialProvider={socialProvider}
            primaryVideoLink={data.primaryVideoLink}
            customThumbnailPath={data?.customThumbnail?.path}
            ttThumbnailUrl={data?.tt?.thumbnailUrl}
            igThumbnailUrl={data?.ig?.thumbnailUrl}
          />
        </div>

        <div className="mt-6 md:mt-0 p-4 lg:p-5 w-full h-full">
          <div className="flex font-bold text-sm mb-3 gap-x-6">
            <span>{abbreviateNumber(data.total.viewCount)} views</span>
            <span>{abbreviateNumber(data.total.likeCount)} likes</span>
          </div>
          {data?.studentName && <h2 className="p-lg font-bold mb-3">{data.studentName}</h2>}
          {data?.customTitle && <h3 className="p-lg font-bold mb-4">{data.customTitle}</h3>}
          {data?.description && (
            <div
              className="p-md text-gray-2 mb-6"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}
          <div className="flex space-x-2 mb-6">
            {data?.tags.map(({ tagTitle }, index) => (
              <button
                className="p-md py-1 px-2 rounded bg-red text-white"
                key={index}
                onClick={() => filterClicked(tagTitle)}
                type="button"
              >
                # {tagTitle}
              </button>
            ))}
          </div>

          <div className="flex gap-x-6">
            {data?.igVideoLink && (
              <a
                href={data.igVideoLink}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full bg-red flex items-center justify-center w-8 h-8"
              >
                <img className="w-4 h-4" src={iconIG} alt="Instagram" />
              </a>
            )}
            {data?.ytVideoLink && (
              <a
                href={data.ytVideoLink}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full bg-red flex items-center justify-center w-8 h-8"
              >
                <img className="w-4 h-4" src={iconYT} alt="YouTube" />
              </a>
            )}
            {data?.tikTokVideoLink && (
              <a
                href={data.tikTokVideoLink}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full bg-red flex items-center justify-center w-8 h-8"
              >
                <img className="w-4 h-4" src={iconTT} alt="Tik Tok" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ImgTT = ({ ttVideoLink }) => {
  const [tikTokThumbnail, setTikTokThumbnail] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      const result = await getTikTokThumbnail(ttVideoLink);
      setTikTokThumbnail(result);
    };

    if (mounted) {
      fetchData();
    }
    return () => (mounted = false);
  }, []);

  return <img className="h-full w-full object-cover" src={tikTokThumbnail} alt="thumbnail" />;
};

const VideoThumbnail = ({ data, socialProvider }) => {
  if (!socialProvider) {
    return null;
  }

  const { ytVideoLink, tikTokVideoLink } = data;

  const customThumbnailPath = data?.customThumbnail?.path;
  const ytThumbnailUrl = data?.yt?.thumbnailUrl;

  return (
    <div className="h-full w-full">
      {customThumbnailPath ? (
        <img
          className="h-full w-full object-cover"
          src={getImageUrl(customThumbnailPath)}
          alt="thumbnail"
        />
      ) : ytVideoLink ? (
        <ReactPlayer
          url={ytVideoLink}
          light={ytThumbnailUrl}
          playIcon={<img src={iconPlay} alt="youtube-play-icon" />}
          playing
          width="100%"
          height="100%"
        />
      ) : (
        <>
          {tikTokVideoLink ? (
            <ImgTT ttVideoLink={tikTokVideoLink} />
          ) : (
            <div className="bg-light h-full w-full"></div>
          )}
        </>
      )}
    </div>
  );
};

export default StoryCard;
