import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { connectSearchBox } from 'react-instantsearch-dom';
import queryString from 'query-string';

import { setRouterSearchQuery } from '../../utils/common';

const field = 'Query_Keyword';

const SearchBox = connectSearchBox(({ refine }) => {
  const location = useLocation();
  const queryStringParsed = queryString.parse(location.search);

  const [queryValue, setQueryValue] = useState('');

  useEffect(() => {
    if (Object.keys(queryStringParsed).length > 0) {
      if (queryStringParsed?.[field]) {
        const value = queryStringParsed[field];
        setQueryValue(value);
        refine(value);
      }
    }
  }, [location.search]);

  const handleChange = (event) => {
    const itemValue = event.target.value;
    setQueryValue?.(itemValue);
  };

  const handleRefine = () => {
    setRouterSearchQuery({ location, queryStringParsed }, queryValue, field);
    refine(queryValue);
  };

  return (
    <div className="w-full rounded-3xl relative shadow-md border-black-light">
      <button
        className="absolute right-4 top-[calc(50%-12px)] p-1"
        type="button"
        onClick={() => handleRefine()}
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8537 6.92683C11.8537 9.92398 9.42398 12.3537 6.42683 12.3537C3.42967 12.3537 1 9.92398 1 6.92683C1 3.92967 3.42967 1.5 6.42683 1.5C9.42398 1.5 11.8537 3.92967 11.8537 6.92683Z"
            stroke="#333333"
            strokeWidth="2"
          />
          <path d="M10.5854 11.0854L15.5 16" stroke="#333333" strokeWidth="2" />
        </svg>
      </button>

      <input
        className="p-md bg-white rounded-3xl border border-white shadow-md md:shadow-none w-full h-[50px] outline-none text-black pl-8 pr-6"
        value={queryValue}
        onChange={handleChange}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleRefine();
          }
        }}
        placeholder="Search by keyword"
      />
    </div>
  );
});

export default SearchBox;
