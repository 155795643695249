import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { connectRefinementList } from 'react-instantsearch-dom';

import { setRouterSearchQuery } from '../../utils/common';

const RefinementList = connectRefinementList(
  ({
    items,
    refine,
    showMore,
    showMoreLimit,
    limit,
    field,
    title,
    renderLabel = (label) => label,
  }) => {
    const location = useLocation();
    const queryStringParsed = queryString.parse(location.search);

    const [extended, setExtended] = useState(false);
    const customLimit = extended ? showMoreLimit : limit;

    const [queryLoaded, setQueryLoaded] = useState(false);

    useEffect(() => {
      if (Object.keys(queryStringParsed).length > 0) {
        // setQueryLoaded(true);
        if (queryStringParsed?.[field]) {
          const values = queryStringParsed[field].split(',');
          refine(values);
        }
      }
    }, [location.search]);

    const filterClicked = (item) => {
      const itemValue = item.value.join(',');
      setRouterSearchQuery({ location, queryStringParsed }, itemValue, field);
      refine(item.value);
    };

    const limitedItems = items.slice(0, customLimit);
    const refinedItems = [];
    const notRefinedItems = [];
    limitedItems.forEach((item) =>
      item.isRefined ? refinedItems.push(item) : notRefinedItems.push(item),
    );

    const renderItem = (item) => (
      <li key={item.label} className="flex items-center mb-2">
        <input
          className="cursor-pointer w-4 h-4"
          type="checkbox"
          id={item.label}
          name={item.label}
          checked={item.isRefined}
          onChange={() => {
            filterClicked(item);
          }}
        />
        <div className="flex justify-between items-center w-full overflow-hidden">
          <label
            htmlFor={item.label}
            className="p-md ml-[14px] max-w-[75%] text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer"
          >
            {renderLabel(item.label)}
          </label>
          <div className="flex justify-center items-center min-w-[36px] h-9 ml-2 rounded-md bg-red text-white">
            <p className="p-md">{item.count}</p>
          </div>
        </div>
      </li>
    );

    return (
      <div>
        <h5 className="font-black p-lg mb-2">{title}</h5>
        <ul>
          {refinedItems.map((item) => renderItem(item))}
          {refinedItems?.length > 0 && (
            <div className="my-4 border-b border-dashed border-black-light" />
          )}
          {notRefinedItems.map((item) => renderItem(item))}
        </ul>

        {showMore && items.length > limit && (
          <button
            className="underline text-red"
            onClick={() => setExtended(!extended)}
            type="button"
          >
            {extended ? `Show Less` : `Show more`}
          </button>
        )}
      </div>
    );
  },
);

export default RefinementList;
